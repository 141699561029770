.medico1-parent {
    background: var(--cream);
    padding: 50px 0px;

}
.faqs_parent5{
    padding: 50px 0px !important;
    background: var(--cream);
    // background: red;

    h2{
    
    text-align: center;
    font-size: 23px;
    padding-bottom: 30px;
    }
}