.medicale-parent{
    padding: 80px 0px;
    background-color:var(--black);
    
        .medicale-container{
            height: auto;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            
    
            .medicale-title{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 10px;
            padding: 20px 0px 0px 0px;

            h2{
               color: var(--golden);
            }
            p{
                color: var(--white);
            }
            
    
    
            }
            
            
    
    
    
    
        }
    }

.medical-parent{
  background: var(--cream);
  padding: 50px 0px ;
}

.medicalse1-parent{
    
    
    background: var(--cream);
  padding: 50px 0px ;

}

.medicalse2-parent{
    
    padding: 50px 0px ;

}
.medicalse3-parent{
    
    padding: 50px 0px ;
    background: var(--cream);

}
.medicalse4-parent{
    
    padding: 50px 0px ;

}
.medicalse5-parent{
    
    padding: 50px 0px ;
    background: var(--cream);

}
.faqs_parent2{
    padding: 50px 0px ;

    h2{
    text-align: center;
    font-size: 23px;
    padding-bottom: 30px;
    }
}
.medicalse1-container  
{
    padding: 50px 0px !important;
}