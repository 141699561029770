.hero-parent {

    width: 100%;
    height: 100svh;
    position: relative;
    // margin-top: 80px;

    .overlay {
        width: 100%;
        height: 100%;
        background-color: #0E4561;
        opacity: 80%;
        position: absolute;
        background-image: url('../assets/hero.jpg');
    }

    .overlay2 {
        width: 100%;
        height: 100%;
        background-color: #00131d;
        opacity: 80%;
        position: absolute;

    }

    .hero-container {
        display: flex;
        gap: var(--gap);
        position: relative;
        height: 100%;

        .hero-left {
            width: 50%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: var(--gap);

            .partner {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;

                .partner-line {}

                .partner-text {}

               
            }
            .hero-p 
            {
                text-align: justify;
            }
            .cta-buttons {
                display: flex;
                gap: var(--gap);
                flex-wrap: wrap;
            }

            h1 {
                color: var(--white);
            }

            p {
                color: var(--white);
                opacity: 0.5;
            }
        }




        .hero-right {
            width: 50%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: var(--gap);

            .hero-right-image {
                aspect-ratio: 1 / 1;
                background: url("../assets/images/hero.png");
                width: 600px;
                height: 600px;
                background-position: center center;
                background-size: cover;
                background-repeat: no-repeat;
            }

            .hero-right-box {
                width: 600px;
                height: 600px;
                aspect-ratio: 1 / 1;
                display: flex;
                flex-wrap: wrap;

                .herobox1 {
                    width: 50%;
                    height: 50%;
                    // background: url("https://img.freepik.com/free-photo/woman-doctor-wearing-lab-coat-with-stethoscope-isolated_1303-29791.jpg?t=st=1711776994~exp=1711780594~hmac=9da60e37b3f4f605c7000485e08fff081c607f6020181081d9b067397094d3c8&w=1380");
                    // ;
                    animation: shuffleAnimation1 5s infinite;
                }

                .herobox2 {
                    width: 50%;
                    height: 50%;
                    display: flex;
                    align-items: flex-end;
                    padding: 10px;

                }

                .herobox3 {
                    width: 50%;
                    height: 50%;
                    display: flex;
                    justify-content: flex-end;
                    padding: 10px;

                }

                .herobox4 {
                    width: 50%;
                    height: 50%;
                    // background: url("https://img.freepik.com/free-photo/woman-doctor-wearing-lab-coat-with-stethoscope-isolated_1303-29791.jpg?t=st=1711776994~exp=1711780594~hmac=9da60e37b3f4f605c7000485e08fff081c607f6020181081d9b067397094d3c8&w=1380");
                    // ;
                    animation: shuffleAnimation4 5s infinite;
                }

                .herobox {
                    display: flex;
                    border-radius: 10px;
position: relative;
                    .hero-child-box {
                        width: 40%;
                        height: 40%;
                        // background: url("https://img.freepik.com/free-photo/woman-doctor-wearing-lab-coat-with-stethoscope-isolated_1303-29791.jpg?t=st=1711776994~exp=1711780594~hmac=9da60e37b3f4f605c7000485e08fff081c607f6020181081d9b067397094d3c8&w=1380");
                        // ;
                        border-radius: 10px;
                    }

                    .hero-child-box1 {
                        animation: shuffleAnimation2 5s infinite;
                    }

                    .hero-child-box2 {
                        animation: shuffleAnimation3 5s infinite;
                    }
                }
            }
        }
    }

}

@keyframes shuffleAnimation1 {

    0%,
    100% {
        border-radius: 10px;
    }

    50% {
        border-radius: 100px;
    }
}

@keyframes shuffleAnimation2 {

    0%,
    100% {
        border-radius: 100px;
    }

    50% {
        border-radius: 10px;
    }
}

@keyframes shuffleAnimation3 {

    0%,
    100% {
        border-radius: 10px;
    }

    50% {
        border-radius: 100px;
    }
}

@keyframes shuffleAnimation4 {

    0%,
    100% {
        border-radius: 100px;
    }

    50% {
        border-radius: 10px;
    }
}

@media only screen and (max-width: 1200px) {
    .hero-parent {
        .hero-container {
            height: auto;
            align-items: flex-start;
            height: 80%;

            .hero-right {


                .hero-right-image {
                    background: url("../assets/images/hero.png");
                    width: 500px;
                    height: 500px;
                    background-position: center center;
                    background-size: cover;
                    background-repeat: no-repeat;

                }


            }
        }
    }
}

@media only screen and (max-width: 1000px) {
    .hero-parent {
        .hero-container {

            height: auto;
            align-items: center;
            flex-direction: column-reverse;
            height: 100%;

            .hero-right {

                width: 100%;

                .hero-right-image {
                    background: url("../assets/images/hero.png");
                    width: 450px;
                    height: 450px;
                    background-position: center center;
                    background-size: cover;
                    background-repeat: no-repeat;

                }


            }

            .hero-left {
                width: 100%;
                text-align: center;
                padding: 0px !important;
                justify-content: flex-start;
                gap: 5px !important;

                .partner {
                    text-align: center;
                    width: 100%;
                }

                h1 {
                    width: 100%;
                    text-align: center;
                    font-size: 30px;
                }
            }

            .cta-buttons {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            p {}
        }
    }
}

@media only screen and (max-width: 650px) {
    .hero-parent {
        .hero-container {
            .hero-left {
                justify-content: center;
            }

            .hero-right {
                display: none;
            }
        }
    }
}


// carousel 