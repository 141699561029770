:root {
    --white: #ffffff;
    --peach: #EE8961;
    --black: #10121D;
    --golden: #E2C16D;
    --dark-blue: #0C122D;
    --cream: #FFF8E5;
    --max-width-s: 1200px;
    --max-width-l: 1400px;
    --gap: 20px;
    --br: 10px;
    --extra-small-font: 14px;
    --small-font: 16px;
    --extra-med-font: 18px;
    --med-font: 22px;
    --large-font: 30px;
    --bold: 500;
    --bolder: 700;
    --boldest: 900;
    --text: rgba(110, 109, 109, 0.685);
    --text2: rgba(224, 224, 224, 0.623);
    --trans: 300ms ease-in;

}

@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Yeseva+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Spectral:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Yeseva+One&display=swap');

*,
*::after,
*::before {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    font-family: 'Poppins', sans-serif;

    // border: 1px solid red;
    scroll-behavior: smooth;
    // font-family: "Ubuntu", sans-serif;
}

.heading,
h1 {
    font-family: "Yeseva One", serif;
    font-weight: 400;
    font-style: normal;

    // font-family: "Spectral", serif;
    // font-weight: 400;
    // font-style: normal;


}

.parent {
    width: 100svw;

    .container {

        margin: auto;
        padding: 0px 10px;
        max-width: var(--max-width-l);
    }
}


body {
    width: 100%;
    overflow-x: hidden;
}

.bg-img-cover {
    background-position: center center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
}

.bg-img-contain {
    background-repeat: no-repeat !important;
    background-size: contain !important;
    background-position: center center !important;
}

p {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.7px;
    opacity: 0.7px;
    word-spacing: 1px;
    color: var(--text);

}

.btn-dark-cream {
    text-decoration: none;
    width: fit-content !important;
    font-weight: 600;
    font-size: 18px;
    color: var(--dark-blue);
    background-color: var(--cream);
    cursor: pointer;
    padding: 10px 30px;
    border-radius: 5px;
    border: none;

    &:hover {}
}

.btn-dark-blue {
    text-decoration: none;
    font-weight: 600;
    font-size: 18px;
    color: var(--golden);
    background-color: var(--dark-blue);
    cursor: pointer;
    padding: 10px 30px;
    border-radius: 5px;
    width: fit-content;
    border: none;
}

a {
    text-decoration: none;
    color: var(--white);
}

.golden-span {
    color: var(--golden);
    font-size: 16px;
    font-weight: 500;
}

.line {
    height: 2px;
    width: 50px;
    border: 2px solid var(--golden);
    border-radius: 10px;
}

h1 {
    font-size: 50px;
    font-weight: 600;
    color: var(--dark-blue);
}

h2 {
    font-size: 30px;
    font-weight: 500;
    color: var(--dark-blue);
}

h3 {
    font-size: 32px;
    font-weight: 600;
    color: var(--dark-blue);
}

.scrolled {
    background-color: var(--white) !important;
    transition: background-color 0.3s ease;

    a {
        color: var(--black) !important;
    }
}

.title {
    color: var(--black);
    font-weight: var(--bold) !important;
    font-size: var(--med-font);
}

.content {
    color: var(--text);
    font-size: var(--small-font);
    font-weight: var(--bold);
    text-align: justify;
}

.healthcare-parent {
    background-color: var(--dark-blue);
    height: 250px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative !important;

    .overlay3 {
        position: absolute;
        width: 100%;
        height: 100%;
        background: var(--dark-blue);
        opacity: 0.75;
    }

    .healthcare-container {
        height: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .healthcare-title {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 10px;

            h2 {
                color: var(--golden);
                z-index: 100;
            }

            .header-top-desc {
                color: var(--white);
                width: 100%;
                text-align: center;
                text-transform: capitalize;
                z-index: 100;
            }



        }






    }
}

.overlay3 {
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--dark-blue);
    opacity: 0.7;
}

// .glass {
//     /* From https://css.glass */
   
//     background: rgba(255, 255, 255, 0.26) !important;
//     border-radius: 16px !important;
//     box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
//     backdrop-filter: blur(5px) !important;
//     -webkit-backdrop-filter: blur(5px) !important;
//     border: 1px solid rgba(255, 255, 255, 0.3) !important;

//     .content-box 
//     {
//         .card-inner-para 
//         {
//             color: rgba(255, 255, 255, 0.774) !important;
//         }
//     }
// }