.header-parent {
    background: transparent;
    height: 60px;
    position: fixed;
    z-index: 11111;

    .header-container {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo-box {
            width: 280px;
            height: 100%;
            padding: 8px;
            background: transparent;
            border-bottom-left-radius: var(--br);
            border-bottom-right-radius: var(--br);
            background-color: var(--white);
            overflow: hidden;

            .logo {
                background: url("../assets/logo_main.webp");
                height: 100%;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }

        .navigations {
            display: flex;
            align-items: center;
            gap: 20px;
            height: 100%;
            // border: 1px solid red;

            a,
            .mobile-menu {
                font-size: var(--small-font);
                text-decoration: none;
                color: var(--white);
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 10;
            }

            .menu {

                position: relative;
                padding: 10px;
                z-index: 20;
                width: fit-content;

                .sub-menu,
                .sub-menu1 {
                    width: 100%;
                    position: absolute;
                    display: none;
                    top: 100%;
                    flex-direction: column;
                    background: var(--white);
                    animation: slideDown 0.3s ease forwards;
                    z-index: 1;
                    border-radius: var(--br);
                    overflow: hidden;
                    box-shadow: 0px 0px 10px rgba(223, 223, 223, 0.651);

                    a {
                        width: fit-content;
                        background: var(--white);
                        padding: 10px 20px;
                        color: var(--black);
                        font-size: var(--small-font);
                    }

                }

                .sub-menu1 {
                    width: 210px;
                }


                &:hover {

                    .sub-menu,
                    .sub-menu1 {
                        display: flex;
                        top: 100%;

                    }
                }


            }
        }

        // burger menu
        .hamburger-menu {
            height: 100%;
            display: none;
            align-items: center;
            justify-content: center;
            color: white;
            scale: 1.1;
            z-index: 101;
        }

        .hamburger-menu1 {
            color: black;
            display: none;
            scale: 1.1;
            height: 100%;
            align-items: center;
            justify-content: center;
            z-index: 101;
        }
    }


    .mobile_navbar {
        width: 100%;
        overflow: hidden;
        height: 100vh;
        background: #000000d0;
        backdrop-filter: blur(10px);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        transition: all 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        animation: slideDown 0.3s ease forwards;

        .mobile-navigations {
            padding: 0px 50px;
            display: flex;
            animation: slideDown 0.3s ease forwards;
            flex-direction: column;
            gap: 20px;

            span {
                font-size: 30px;
            }

            a,
            .mobile-menu {
                width: fit-content;
                font-size: 30px;
                opacity: 0.6;
                cursor: pointer;
                color: var(--white);

                &:hover {
                    opacity: 1;
                }

                // border: 1px solid red;
            }

            .mobile-menu {
                overflow: hidden;

                .mobile-sub-menu {
                    height: 0px;
                }

                .mobile-sub-menu2 {
                    height: fit-content;
                    flex-direction: column;
                    animation: slideDown 0.3s ease forwards;
                    gap: 5px;
                    display: flex;
                    flex-direction: column;

                    a {
                        cursor: pointer;
                        padding-left: 25px;
                        font-size: 22px;

                    }

                }
            }

            // .mobile-menu {
            //     width: fit-content;

            //     .mobile-sub-menu {
            //         display: none;
            //         flex-direction: column;
            //         animation: slideDown 0.3s ease forwards;

            //         gap: 5px;
            //         a
            //         {
            //             cursor: pointer;
            //             padding-left: 25px;
            //             font-size: 22px;

            //         }
            //     }

            //     &:hover {
            //         .mobile-sub-menu {
            //             display: flex;
            //         }
            //     }
            // }

        }

    }

}

.scrolled {
    background-color: var(--white) !important;
    transition: background-color 0.3s ease;
    box-shadow: 0px 0px 10px rgba(223, 223, 223, 0.651);

    a {
        color: var(--black) !important;
    }

    .mobile_navbar {
        .mobile-navigations {
            a {
                color: var(--white) !important;
            }
        }
    }
}

@keyframes slideDown {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}


@media only screen and (max-width:1200px) {
    .header-parent {
        .header-container {
            .navigations {
                display: none;
            }

            .hamburger-menu,
            .hamburger-menu1 {
                display: flex;
            }
        }
    }

}

@media only screen and (max-width:650px) {
    .mobile_navbar {
        a {
            font-size: 20px !important;

            span {
                font-size: 18px !important;

            }
        }

        .mobile-navigations {

            width: 100%;
            padding: 0px 10px !important;

            .mobile-menu {
                font-size: 20px !important;

                .mobile-sub-menu1 {
                    padding: 10px 0px;

                    a {
                        font-size: 16px !important;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:650px) {
    .header-parent {
        background-color: var(--white) !important;

        .header-container {
            padding: 0px 5px !important;

            .logo-box {
                width: 220px;
            }
        }
    }

    .hamburger-menu {
        color: black !important;

    }
}

.hamburger-change {
    color: var(--white) !important;
}

.test-menu 
{
    color: white !important;
    position: absolute;
    top: 10px;
    right: 20px;
    scale: 1.3;
}