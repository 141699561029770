.advoacte-parent {
    background: var(--cream);
    padding: 50px 0px;

}
.advocatee-parent{
    padding: 50px 0px;

}
.advocatee1-parent{
    background: var(--cream);
    padding: 50px 0px;

}
.advocatee2-parent{
    background: var(--cream);
    padding: 50px 0px;

}

.faqs_parent4{
    padding: 50px 0px ;

    h2{
    text-align: center;
    font-size: 23px;
    padding-bottom: 30px;
    }
}