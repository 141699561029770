.expertise-parent {
    // border: 1px solid red;
    // padding: 150px 0px;
    padding: 50px 0px;
    .expertise-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: var(--gap);
    }

    .title {
        color: var(--white);
        z-index: 2;
    }

    .card-section {
        display: flex;
z-index: 2;
        gap: var(--gap);
    }
}

@media only screen and (max-width: 1150px) {
    .expertise-parent {
        .card-section {
            flex-direction: column !important;
        }
    }
}

.expertise-swiper 
{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid red ;

    .swiper-slide 
    {
        width: 100%;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}