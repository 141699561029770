.section-divide-card-parent {
    background-color: var(--dark-blue);
    padding: 10px 0px;
    border-radius: 10px;
    width: 1000px;
    margin: auto;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    overflow: hidden;

    .divider-title {
        color: var(--white);
        font-weight: var(--bold) !important;
        font-size: var(--med-font);
        width: 100%;
        text-align: center;
        text-transform: capitalize;
        z-index: 1;
    }

    .divider-content {
        width: 80%;
        text-align: center;
        color: var(--text2);
        font-size: var(--small-font);
        z-index: 1;
    }

    .bk-btn {
        z-index: 1;
    }
    .vdoverlay 
    {
        background: var(--dark-blue);
        opacity: 0.85;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
}

@media only screen and (max-width: 1050px) {
    .section-divide-card-parent {
        width: 100%;
        border-radius: 0px;
    }
}