.about-parent 
{
    width: 100%;
    height: fit-content;
   
    .about-divider-parent 
    {
        background-color: var(--cream);
        position: relative;
    }
    .about-divider-founders 
    {
        padding: 50px 0px;
        .about-divider-founder-container 
        {
            .founder-title
            {
                width: fit-content;
                margin: auto;
                font-size: 22px;
                padding: 5px 0px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .founder-desc
            {
                width: fit-content;
                margin: auto;
                padding: 5px 0px;
      
            }
            .founders-box 
            {

               
            }
        }
    }
}
@media only screen and (max-width: 400px) {
    .founder-title 
    {
        font-size: 22px !important;
        width: 100% !important;
        padding: 5px 0px !important;
    }
    .founder-desc
            {
                text-align: center;
                margin: auto;
                padding: 5px 0px;
      
            }
            .about-divider-founders 
            {
                padding: 0px !important;
                .about-divider-founder-container 
                {
                    padding: 0px !important;
                }
            }
            .founders-box 
            {
                padding: 0px 10px !important;
            }
  }