.homeparent {



    .divider-container {
        position: absolute;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.faqs_parent
{
   
    .faqs_container_home
    {
     
    }
}

.loadmore-btn 
{
    margin: 10px 0px;
}

.home-experties {
    // background-color: var(--cream);
    // padding: 10px 0px;
    position: relative;
   
}

.trust-container {
    padding: 70px 0px !important;
}
.testimonial-container 
{
    position: relative;
    // margin: 50px 0px;
}
.overlay4 
{
    background: var(--dark-blue);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.75;
}
.bg-vid-4 
{
    z-index: -2;
}
.overlay5 
{
    background: var(--dark-blue);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.75; 
}

.footer-before 
{
    // border: 1px solid red;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 150px 0px;
}