.cosmetic-about {
    padding: 50px 0px;
}

.cosmetic-about2 {
    padding: 50px 0px;
    background-color: var(--cream);
}

.cosmetic_contact {
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    background: var(--cream);
    padding: 50px 0px;

    .cosmetic_contact_container {
        padding: 50px 0px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .cosmetic_contact_left {
            width: 50%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: justify;
            gap: 10px;

            h2 {
                text-align: center;
            }

            .contact-text {
                color: var(--text);
            }
        }

        .contact_page_right {
            width: 50%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .form_box {
                width: 80%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 10px;
            }
        }
    }
}

.down-broch {
    position: fixed;
    bottom: 10px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: 1px solid var(--golden);
    z-index: 10000;

    .don {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media only screen and (max-width: 1200px) {
    .cosmetic_contact {
        .cosmetic_contact_container {
            padding: 50px 10px;
        }
    }
}

@media only screen and (max-width: 850px) {
    .cosmetic_contact {
        .cosmetic_contact_container {
            flex-direction: column;

            .cosmetic_contact_left {
                width: 100%;
                padding: 0px 0px 20px 0px;
            } 

            .contact_page_right {
                width: 100%;
            }
        }
    }
}