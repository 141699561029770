.clinical-parent{
padding: 50px 0px;
background-color:var(--black);

    .clinical-container{
        height: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        

        .clinical-title{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
        padding: 20px 0px 0px 0px;

        h2{
            color: var(--golden);
         }
         p{
             color: var(--white);
         }
        


        }
        
        




    }
}
.accorliwhy-parent{
    background: var(--cream);
    padding: 40px 0px ;
}

.strc-parent{
    
    padding: 50px 0px ;
}

.nutritio-parent{
    background: var(--cream);
    
    padding: 50px 0px ;
}


.faqs_parent1{
    padding: 50px 0px ;

    h2{
    text-align: center;
    font-size: 23px;
    padding-bottom: 30px;
    }
}



