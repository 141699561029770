.about-divider {
    padding: 50px 0px;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .about-divider-left {
        height: 100%;
        z-index: 111;
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        gap: var(--gap);

        .about-divider-heading {
            color: var(--white);
        }

        .about-divider-content {
            color: var(--white);
        }

        .about-divider-counter {

            display: flex;
            gap: var(--gap);

            .counter-box {

                width: fit-content;
                display: flex;
                flex-direction: column;
                align-items: center;

                .counter-number {
                    color: var(--white);
                    font-size: var(--large-font);
                    font-weight: 700;
                    width: 100px;
                    display: flex;
                    align-items: center;justify-content: center;
                }

                .counter-text {
                    color: var(--white);
                    font-size: var(--extra-small-font);
                }
            }
        }
    }

    .about-divider-right {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 40%;

        .about-divider-right-image {
            z-index: 111;
            aspect-ratio: 1 / 1;
            // background: url("../photos/About\ us/");
            width: 340px;
            height: 340px;
            background-position: center center !important;
            background-size: cover !important;
            background-repeat: no-repeat !important;
            border-radius: var(--br);
        }
    }
}

@media only screen and (max-width: 1000px) {
    .about-divider {
        flex-direction: column-reverse;
        gap: 5px;

        .about-divider-right {
            .about-divider-right-image {
                width: 300px;
                height: 300px;
            }
        }

        .about-divider-left {
            width: 100%;
            align-items: center;

            .about-divider-heading {
                text-align: center;
            }
        }
    }
}