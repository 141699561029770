.layality-parent {
    padding: 60px 0px ;

    .layality-container {
        // width: 100%;
        // height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .subheaddig {
            text-align: center !important;
            font-size: 25px !important;

        }

        .loyality-card-container {
            // border: 1px solid green;
            padding: 50px 0px 30px 0px;
            height: 100%;
            width: 100%;
          


            .swiper-backface-hidden .swiper-slide {
                padding-bottom: 20px!important;
            }
            .lmySwiper {
                width: 100%;
                height: 100%;

              
            }

            .loyality-card {
overflow: hidden;
                display: flex;
                flex-direction: column;
                border-radius: 15px;
                gap: 10px;
                background-color: var(--cream);
                padding: 10px 10px 50px 10px;
                overflow: hidden;
                width: 350px;
                height: 650px !important;
                margin: auto;
                overflow: hidden;


                .lcard-img1 {
                    background: url('../photos/loyality/personal.webp');
                    width: 100%;
                    height: 250px;
                    border-radius: 15px;

                }

                .lcard-img2 {
                    background: url('../photos/loyality/family.webp');
                    width: 100%;
                    height: 250px;
                    border-radius: 15px;
                }

                .lcard-img3 {
                    background: url('../photos/loyality/drcomm.webp');
                    width: 100%;
                    height: 250px;
                    border-radius: 15px;
                }


                .lcard-content {

                    padding: 15px;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .lcard-price {
                        display: flex;
                        align-items: center;
                        gap: 20px;

                        .euro {
                            color: var(--golden);
                            display: flex;
                            align-items: center;
                        }
                    }

                    .icon-box {
                        display: flex;
                        gap: 10px;
                        align-items: center;
                        font-size: 20px;

                        .licon {
                            color: var(--golden);
                        }
                    }

                    .l-btn {
                        width: 100%;
                        text-align: center;
                        margin: 10px 0px;
                    }
                }

            }

        }


    }

}



.loyality-baner-parent {

    .overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.394);
        z-index: 2;
    }

    background: url('../assets/dr1.jpg');
    background-position: center center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    position: relative;

    .loyality-baner-cintainer {
        padding: 100px 10px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        z-index: 100;
        position: relative;

        .banner-title {
            color: white;
            font-size: 40px;
            text-align: center !important;
            font-weight: 700;
        }

        .banner-text {
            color: white;
            font-size: 22px;
            text-align: center !important;
            font-weight: 500;
        }

        .banner-txt {
            color: white;
            text-align: center !important;
            display: none;
        }

        .banner-link {
            color: var(--golden);

        }
    }
}

@media only screen and (max-width:1100px){

    .swiper-button-next:after {
        color: var(--golden);
        display: block;
        font-size: 30px;

    }

    .swiper-button-prev:after,
    .swiper-rtl .swiper-button-next:after {
        display: block;
        color: var(--golden);
        font-size: 30px;
    }
}
@media only screen and (min-width:1100px){

    .swiper-button-next:after {
        display: none;

    }
    .swiper-button-prev:after,
    .swiper-rtl .swiper-button-next:after {
        display: none;
    }

}
