.popup-btn-box {
    width: fit-content;
    height: fit-content;
    position: fixed;
    bottom: 150px;
    right: 0px;
    z-index: 1000;
    transition: 300ms all cubic-bezier(.17, .67, .83, .67);
    cursor: pointer;

    &:hover {
        right: 0px;
    }

    .whatsapp {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: fit-content;
        gap: 10px;

        .icons-boxs {
            display: flex;
            align-items: center;
            justify-content: center;


            .w-icon {
                background-color: #0BC040;
                color: white;
                padding: 10px;
                font-size: 22px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }

            .c-icon {
                background-color: #39A2F7;
                color: white;
                padding: 10px;
                font-size: 22px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;

            }





        }
    }
}