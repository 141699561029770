.testimonial-wrapper {
    background-color: var(--cream);

    .testimonial-container {
z-index: 2;
        width: 100%;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: var(--gap);
        padding: 50px 0px;

        .testimonial-heading {
            color: var(--white);
            font-weight: var(--bold) !important;
            font-size: var(--med-font);
        }

        .carousel-box {
            width: 100%;
            height: fit-content;

            .swiper-parent {
                width: 100%;
                height: 100%;

                .swiper-slides {
                    margin-bottom: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    gap: 10px;
                    border-radius: 10px;
                    padding: 15px !important;
                                 
                    padding: 0px 0px 25px 0px;

                    .slide-text {

                        color: var(--white);
                        font-weight: var(--bold) !important;
                        font-size: var(--small-font);
                        text-align: justify;



                    }

                    .slide-author {
                        color: var(--white);
                        font-weight: var(--bold) !important;
                        font-size: var(--small-font);
                    }
                }
            }
        }
    }

    .swiper-pagination-bullet-active {
        background: var(--golden) !important;

    }

    .swiper-pagination-bullet {
        background-color: var(--golden) !important;
    }

    @media only screen and (max-width: 850px) {
        .testimonial-wrapper {
            .carousel-box {
                .swiper-parent {
                    .swiper-slides {
                        .slide-text {
                            width: 100%;
                            padding: 0px 20px;
                        }
                    }
                }
            }
        }
    }
}