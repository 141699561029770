.blogcard-parent {
    // border: 1px solid green;
    width: 450px;
    height: 200px;
    border-radius: var(--br);
    display: flex;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor: pointer;
    .blogcard_left {
        height: 100%;
        width: 40%;
        padding: 5px;

        .blogcard_left_img {
            background-position: center !important;
            background-size: cover !important;
            background-repeat: no-repeat !important;
            width: 100%;
            height: 100%;
            border-radius: var(--br);
        }
    }

    .blogcard_right {
        padding: 5px;
        width: 60%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: center;
        justify-content: center;

        .blog_card_top {
            width: 100%;
            // background: var(--cream);
            // padding: 5px 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: var(--black);
        }

        .blog_card_title {
            display: flex;
            flex-direction: column;
            gap: 5px;
          

            .blog-title {
                font-size: var(--med-font);
      
            }

            .blog_card_content {
                font-size: var(--extra-small-font);
                color: var(--text);
              
                text-align: justify;
            }
        }
    }
}
@media only screen and (max-width: 600px)
{
    .blogcard-parent { 
        flex-direction: column;
        height: fit-content !important;
        .blogcard_left { 
            width: 100%;
            height: 250px;
        }
        .blogcard_right 
        {
            width: 100%;
            // background: #000;
        }
    }
}