.services-parent 
{
    width: 100%;
    height: auto;
    padding-top: 60px;
  
    .medical-service-conainer 
    {  display: flex;
        gap: var(--gap);
        flex-direction: column;
        .medical-service-title 
        {
            margin: auto;
            width: fit-content;
        }
        .medical-service-description 
        {
            width: fit-content;
            margin: auto;
        }
    }
    .services-last-section 
    {
        display: flex;
        flex-direction: column;
        gap: var(--gap);
        align-items: center;
        justify-content: center;
        padding: 40px 0px;
    }
}