.contact_wrapper {
    width: 100%;
    height: fit-content;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 100px 0px;
    background: var(--cream);

    .contact_main {
        padding: 20px;
        border-radius: var(--br);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        background: var(--white);

        .contact_page_left {
            width: 50%;
            border-radius: calc(var(--br));
            padding: 0px 100px;
            display: flex;
            gap: calc(var(--gap) * 3);
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            background: url("../assets/images/background.svg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center bottom;

            h2 {
                color: var(--golden);
            }

            .left_contact_page_box {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: var(--gap);

                .left_inner_box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: var(--gap);

                    span {
                        color: var(--golden);
                        font-size: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    h3 {
                        color: white;
                        font-size: 22px;
                    }
                }

                .social_icons_box {
                    width: 100%;
                    height: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: var(--gap);

                    a {
                        border: 1px solid white;
                        padding: 10px;
                        font-size:  24px;
                        color: var(--golden);
                        border-radius: var(--br);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                    }
                }
            }
        }

        .contact_page_right {
            width: 50%;
            height: 100%;
            padding: 50px 0px;

            .button_box {
                width: 100%;
                height: fit-content;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .btn-cross {
                    font-size: 30px;
                    padding: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: none;
                    cursor: pointer;
                }
            }

            .form_box {
                width: 80%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                gap: var(--gap);
                margin: auto;

                form {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 20px;
                    flex-direction: column;

                    .input_box {
                        width: 60%;
                        height: 40px;
                        border-radius: 5px;
                        background: white;
                        display: flex;
                        overflow: hidden;
                        border: 1px solid #dadada;

                        .input_logo {

                            height: 100%;
                            font-size: 20px;
                            padding: 5px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #c4c3c3;

                        }

                        input {
                            width: 100%;
                            height: 100%;
                            border: none;
                            font-size: 16px;
                            color: var(--black);
                            padding: 0px 5px;

                            &:focus {
                                outline: none;
                            }
                        }

                    }
                }


                textarea {
                    width: 60%;
                    border-radius: 5px;
                    border: 1px solid #dadada;
                    font-size: 16px;
                    padding: 5px;
                    color: var(--black);

                    &:focus {
                        outline: none;
                    }

                }
            }
        }
    }


}

#filled-basic {
    width: 100%;
    outline: none;
    border: none;
}

@keyframes slideFromBottom {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(0%);
    }
}

@media only screen and (max-width:1100px) {
    .contact_main {
        flex-direction: column-reverse;
        .contact_page_left 
        {
            width: 100% !important;
        }
        .contact_page_right 
        {
            width: 100% !important;
        }
    }
}
@media only screen and (max-width:700px) {
    .contact_main {
    padding: 0px !important;
    border-radius: 0px !important;
    box-shadow: none !important;
        .contact_page_left 
        {
            padding:10px 10px !important;
            border-radius: 0px !important;
            h2 
            {
                font-size: 22px;
            }
            .left_contact_page_box
            {
               .left_inner_box 
               {
                h3 
                {
                    font-size: 18px !important;
                }
               } 
            }
        }
        .contact_page_right 
        {
            width: 100% !important;
        }
    }
    .contact_wrapper
    {
        background: var(--white);
    }
}