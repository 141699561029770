.clinical-parent{
    padding: 60px 0px 60px 0px;
    
        .clinical-container{
            height: auto;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            
    
            .clinical-title{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 10px;
            padding: 20px 0px 0px 0px;
                
                }
                                  
            }
    }
    
    .stresswhy-parent{
        background: var(--cream);
        padding: 50px 0px 50px 0px;
    }
    
    .nutritionwhy-parent{
        
        margin: 50px 0px 50px 0px;
    }
    
    .personalwhy-parent{
        background: var(--cream);
        padding: 50px 0px 50px 0px!important;
    
    }

.clinicalwhy-parent1
{
    
    padding: 50px 0px 50px 0px!important;
}


.card-bg1{
    background-color: var(--cream);
}