.ourservice-container {
    height: fit-content;

    .ourservice-swiper {
        width: 100%;
        height: auto;
        padding: 25px 0px 50px 0px;
        display: flex;
        align-items: center;
        justify-content: center;

        .serviceswiper-slide , .swiper-div {
            width: 250px !important;
            height: 300px;
            border: 1px solid white;
            padding: 10px;
            border-radius: 10px;
            background-color: white;

            .service-swiper-img {
                width: 100%;
                height: 70%;
                border-radius: 10px;
                background: url("https://images.pexels.com/photos/2265488/pexels-photo-2265488.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
            }

            .service-swiper-text {
                width: 100%;
                height: 15%;
                font-size: 16px;
                // color: var(--white);
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .swiper-service-btn {
                color: var(--golden);
                font-size: var(--extra-small-font);
                display: flex;
                align-items: center;
                justify-content: center;
                transition: var(--trans);
                height: 15%;
                border: 1px solid var(--dark-blue);
                width: 100%;

                span {
                    color: black;
                    display: flex;
                    align-items: center;
                }
            }

        }
    }

    .ser-text {
        color: var(--white);
        text-align: center;
        padding-top: 25px;
        font-size: 22px;
    }
}

.swiper-pagination-bullet-active {
    color: var(--golden) !important;
}

.swiper-slide-mobile 
{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}