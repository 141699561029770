.footer-parent {
    background-color: var(--dark-blue);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;


    .top-footer-container {
        display: flex;
        gap: 40px;
        padding: 20px 10px;
        overflow: hidden;
        justify-content: space-around;

        .col-title {
            color: var(--golden);
            font-size: 18px;

        }

        a {
            text-decoration: none;
            color: rgba(224, 224, 224, 0.845)!important;
            font-size: 16px;
            &:hover{
                color: var(--white)!important;
            }

        }

        .column1 {
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 20%;


        }

        .column2 {
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 25%;

        }

        .column3 {
            width: 30%;
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        .column4 {

            width: 25%;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .icon-box {
                display: flex;
                gap: 10px;

                .icon {
                    color: var(--golden);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 8px;
                    font-size: 15px;
                    border-radius: 100%;
                    border: 1px solid white;

                }

            }

            .social-icon-box {
                display: flex;
                gap: 10px;
                padding-top: 10px;

                color: var(--golden)!important;

                .icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 8px;
                    font-size: 15px;
                    border-radius: 100%;
                    border: 1px solid white;
                    color: var(--golden)!important;

                }
            }

        }



    }

    .bottom-footer-parent {
        background-color: black;
        color: rgba(224, 224, 224, 0.845)!important;


        .bottom-footer-container {
            display: flex;
            gap: 10px;
            justify-content: space-between;
            padding: 15px 10px;
            flex-wrap: wrap;


            .copyright {
                font-size: 14px;
               a{

               } color: rgba(224, 224, 224, 0.845)!important;
            }

            .policies {
                display: flex;
                gap: 10px;
                justify-content: space-evenly;
               
                a{
                color: rgba(224, 224, 224, 0.845)!important;
                }

                font-size: 16px;

                .policy {
                    color: rgba(224, 224, 224, 0.845)!important;
                    font-size: 16px;
                }
            }

        }


    }

}


@media only screen and (max-width: 1000px) {
    .footer-parent {
        .top-footer-container {
            flex-wrap: wrap;
            width: 100%;
            gap: 20px;
            align-items: center;
            justify-content: center;



            .column {
                width: 45%;
                align-items: start;
                justify-content: center;

            }

        }

        .footer-bottom {

            width: 100%;

            .bottom-footer-parent {

                .bottom-footer-container {
                    align-items: center;
                    justify-content: center;

                    .copyright {
                        text-align: center;
                    }

                }
            }
        }
    }
}



@media only screen and (max-width: 600px) {
    .footer-parent {
        .top-footer-container {

            .column1,
            .column2,
            .column3 {
                border-bottom: 1px solid rgba(108, 104, 104, 0.903);
                padding-bottom: 15px;
            }

            .column {
                align-items: start;
                justify-content: center;
                width: 90% !important;
                padding: auto 50px !important;
            }
        }
    }

}


@media only screen and (max-width: 900px) {
    .footer-parent {

        .bottom-footer-parent {

            .bottom-footer-container {
                align-items: center;
                justify-content: center;

                .copyright {
                    text-align: center;
                }

            }
        }
    }
}