.blog_detail_parent {
    width: 100%;
    height: fit-content;

    .blog_detail_container {
        width: 100%;
        height: 100%;
        padding: 5px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border-radius: var(--br);

        .blog_detail_top {
            height: 400px;
            border-radius: var(--br);
            background-position: center !important;
            background-repeat: no-repeat !important;
            background-size: cover !important;

        }

        .blog_detail_bottom {
            height: fit-content;

            .bottom_details {

                height: fit-content;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 20px;

            }

            .blog_detail_heading {
                padding: 10px 20px;
                background: var(--cream);

                h1 {
                    font-size: var(--large-font);
                }
            }

            .blog_detail_content {
                height: fit-content;
                text-align: justify;
                padding: 10px;
            }
        }
    }
}