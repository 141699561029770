// * {
//    border: 1px dotted red;
// }

.expertise-card {
   width: 250px;
   border-radius: var(--br);
   box-shadow: 0px 0px 10px #acabab;
   padding: 15px;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   gap: 10px;
background: var(--white);
   .expertise-img {
      height: 200px;
      background-position: center center !important;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      border-radius: var(--br);
   }

   .expertise-card-title {
      width: 100%;
      font-size: var(--small-font);
      text-transform: capitalize;
      font-weight: 500;

   }

   .expertise-card-content {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .expertise-card-content-box {
         display: flex;
         align-items: center;
         gap: 10px;

         span {
            display: flex;
            align-items: center;
            font-size: var(--med-font);
         }

         color: var(--text);
         font-size: var(--extra-small-font);
      }
   }

   .expertise-card-button {
      a {
         color: black;
         font-size: var(--extra-small-font);
         display: flex;
         gap: 2px;
         transition: var(--trans);

         span {
            color: black;
            display: flex;
            align-items: center;
         }

         &:hover {
            gap: 8px;

         }
      }
   }
}