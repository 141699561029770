.whychoose-parent {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;

    .whychoose-left {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;

        justify-content: center;

        .whychoose-img {
            // background: url("https://img.freepik.com/free-photo/medium-shot-woman-posing-with-question-marks_23-2150408207.jpg?t=st=1709012207~exp=1709015807~hmac=85a121a85740d2ab9731d2e3307f2283653232ca9a27c00103e5309174b919f9&w=900");
            width: 512px;
            height: 512px;
            border-radius: var(--br);
            background-position: center center !important;
            background-repeat: no-repeat !important;
            background-size: cover !important;
        }
    }

    .whychoose-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        height: 100%;
        gap: var(--gap);

        .whychoose-header {
            color: var(--black);
            font-weight: var(--bold) !important;
            font-size: var(--med-font);
        }

        .whychoose-content {
            color: var(--text);
            font-size: var(--small-font);
            font-weight: var(--bold);
            text-align: justify;
        }

    }
}

@media only screen and (max-width: 1150px) {
    .whychoose-parent {

        flex-direction: column !important;

        .whychoose-left {
            width: 100%;

            .whychoose-img {
                aspect-ratio: 1 / 1;
            }


        }

        .whychoose-right {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 1000px) {
    .whychoose-left {
        width: 100%;

        .whychoose-img {
            aspect-ratio: 1 / 1;
            width: 400px !important;
            height: 400px !important;
        }


    }

    .whychoose-right {
        padding: 20px 0px 0px 0px;
        // border: 1px solid red;
    }
}

@media only screen and (max-width: 600px) {
    .whychoose-left {
        width: 100%;

        .whychoose-img {
            aspect-ratio: 1 / 1;
            width: 300px !important;
            height: 300px !important;
        }


    }

    .whychoose-right {
        .whychoose-header {
            // border: 1px solid red;
            
            text-align: center;
        }

        .whychoose-content {
            // border: 1px solid red;
        }
    }

}