.executive_health{

    padding-top: 50px!important;
}

.faqs_parent5{
    padding: 50px 0px ;
    background: var(--cream);

    h2{
    text-align: center;
    font-size: 23px;
    padding-bottom: 30px;
    }
}