.card-parent {
  // padding: 50px 0px;
  position: relative;

  .overlay5 {
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--dark-blue);
    z-index: 1;
    opacity: 0.75;
  }

  .card-container {
    height: 100%;
    justify-content: center;
    align-items: center;

    .card-content-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;



      .card-short-title {
        color: var(--accent);
        font-weight: 400;

        .line {
          height: 2px;
          background: var(--accent);
          width: 100%;
        }
      }

      .card-title {
        text-align: center;
        font-size: 24px;
      }

      .card-description {
        width: 80%;
        text-align: center;
        color: var(--text);
        text-align: justify;
      }
    }

    .card-box {
      padding: 40px 0px;
      display: flex;
      flex-wrap: wrap;
      align-items: start;
      justify-content: space-between;

      .card-headding {
        font-size: 20px;



      }

      p {
        text-align: left;

      }

      ul {
        list-style-type: none;

        li {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          text-align: left;
          gap: calc(var(--gap) / 2);
          padding: 5px 0px;
          color: var(--text);

          span {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            font-size: 18px;
            color: var(--dark-blue);
          }
        }
      }





      .swiper {
        width: 100%;
        height: 100%;


        .swiper-button-next {
          color: var(--accent) !important;
          font-size: 10px !important;

        }

        .swiper-button-prev {
          color: var(--accent) !important;
          font-size: 10px !important;

        }

        .swiper-button-next:after,
        .swiper-rtl .swiper-button-prev:after {
          font-size: 25px;
        }

        .swiper-button-prev:after,
        .swiper-rtl .swiper-button-next:after {
          font-size: 25px;
        }

        .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
        .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
          background: var(--accent);

        }

      }

      .swiper-slide {
        text-align: center;
        font-size: 18px;
        margin-bottom: 30px;
        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;
      }

      //   gap: 30px;

      .card {
        margin: auto;
        height: fit-content;
        width: 400px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        border: 1px solid #dadada;
        background-color: white;
        padding: 10px;
        // box-shadow: var(--box-shadow);
        border-radius: 10px;

        .content-box {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          text-align: justify;
          gap: 20px;
          width: 100%;

          .img-main-card {
            width: 100% !important;
            height: 250px;
            border-radius: 10px;
          }

          h3 {
            text-align: left;
          }

        }


      }
    }


  }
}

@media only screen and (max-width:1250px) {

  .swiper-button-next:after {
    color: var(--golden) !important;
    display: block !important;
    font-size: 30px;

  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    display: block !important;
    color: var(--golden) !important;
    font-size: 30px;
  }
}

@media only screen and (min-width:1250px) {

  .swiper-button-next:after {
    display: none;

  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    display: none;
  }

}

.card-inner-para {
  text-align: justify !important;
  overflow: auto !important;

}

.swiper-wrapper 
{
    justify-content: space-evenly;
}