.section-indicator-parent {
    display: flex;
    align-items: center;
    gap: 5px;
     
    .section-name {
        font-size: var(--extra-med-font);
        font-weight: 500;
        color: var(--golden);
        text-transform: capitalize;
    }
}

@media only screen and (max-width: 600px)
{
    .section-indicator-parent
    {
        flex-direction: column-reverse;
        width: fit-content;
        .line
        {
            width: 100%;
        }
    }
}