.elective-parent 
{
    padding: 50px 0px;
}
.electives-cta-parent 
{
    padding: 150px 0px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.elective-swiper 
{
    background-color: var(--cream);
}