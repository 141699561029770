.accordian-container {
    overflow: hidden;
    border-top: 1px solid #dadada;

    .accor-title {
        cursor: pointer;
        display: flex;
        gap: 10px;
        padding: 10px 0px;

        .accor-arrow {
            background: #000;
            width: 25px;

            height: 25px;
            display: flex;
            font-size: 14px;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            transition: transform 0.3s ease;
            transform: rotateZ(180deg);

            &.open {
                transform: rotateZ(0deg);
            }
        }
    }

    .accor-desc {
        height: 0;
        opacity: 0;
        transition: height 0.3s ease, font-size 0.3s ease;
        overflow: hidden;
        color: var(--text);
        transition: transform 0.3s ease;

        &.open {
            height: fit-content;
            opacity: 1;
            font-size: 16px;
            padding: 0px 0px 0px 40px;
            transition: transform 0.3s ease;
            padding-bottom: 5px;
        }
        ul {
            list-style: none;
            

            li {
                display: flex;
                gap: calc(var(--gap) / 2);
                padding: 5px 0px;
                color: var(--text);
                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 18px;
                    color: var(--dark-blue);
                }
            }
        }
    }

    // .accor-list {
    //     padding: 0px 0px 0px 40px;

        
    // }
}