
    .healthcare1-parent{
  
        background: var(--cream);
        padding: 50px 0px ;
      
      }
      .healthcare2-parent{
  
        
        padding: 50px 0px ;
      
      }  

      .healthcare3-parent{
  
        background: var(--cream);
        padding: 50px 0px ;
      
      }

      .faqs_parent3{
        padding: 50px 0px ;
    
        h2{
        text-align: center;
        font-size: 23px;
        padding-bottom: 30px;
        }
    }