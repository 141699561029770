.about-main {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 50px 0px;

    .about-main-left {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .about-main-left-img {
            aspect-ratio: 1 / 1;
            width: 512px;
            height: 512px;

            background-position: center center !important;
            background-repeat: no-repeat !important;
            background-size: cover !important;
            border-radius: var(--br);
        }
    }

    .about-main-right {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
        .about-main-markbox {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 20px;
            height: 100%;
            .markbox {
                font-size: var(--small-font);
                display: flex;
                align-items: center;
                width: 50%;
                height: 30px;
                flex-grow: 0;
                flex-shrink: 0;
                box-sizing: border-box;
                gap: 10px;


                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 25px !important;
                    font-weight: var(--bolder) !important;
                }
            }
        }

        .about-main-heading {
            .founder-degree {
                font-size: var(--extra-small-font) !important;
            }
        }
    }
}
.about-main-desc 
{
    height: auto;
    padding: 0px 10px;
}

@media only screen and (max-width: 1150px) {
    .about-main {
        height: auto !important;
        flex-direction: column !important;
        align-items: center;

        .about-main-right {
            justify-content: center;
            align-items: center;
            width: 100%;
        }

        .about-main-markbox {
            width: 100%;
            gap: 35px !important;
            flex-direction: row !important;
            flex-wrap: wrap !important;
            align-items: center !important;
            justify-content: center !important;

            .markbox {
                width: fit-content !important;

            }
        }
    }
}

@media only screen and (max-width: 1000px) {
    .about-main {
        .about-main-left {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .about-main-left-img {
                aspect-ratio: 1 / 1;
                width: 400px;
                height: 400px;
            }
        }

    }
}

@media only screen and (max-width: 450px) {
    .about-main {
        gap: var(--gap);

        .about-main-left {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .about-main-left-img {
                aspect-ratio: 1 / 1;
                width: 300px;
                height: 300px;
            }
        }

        .about-main-right {
            .about-main-markbox {
                justify-content: flex-start !important;
                padding: 20px 0px;
                gap: 10px !important;
            }
        }
    }
}

@media only screen and (max-width: 450px) {

    .about-main {
        .about-main-right {
            .about-main-markbox {
                justify-content: flex-start !important;
                padding: 20px 0px;
                gap: 10px !important;
            }
        }
    }
}