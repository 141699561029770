.header-top-parent {
    height: 250px;
    width: 100%;
    background-color: var(--dark-blue);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

   
    h1 {
        color: var(--white);
    }

}

.bg-vid 
{
    opacity: 0.85 !important;
}
