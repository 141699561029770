.faqs-parent{
    display: flex;
    align-items: center;
    justify-content: center;


.faqs_container {
    
    width: 70%;
    
    height: fit-content;
    box-shadow: 0px 0px 10px rgba(143, 142, 142, 0.3);
    border-radius: 5px;
    margin-bottom: 15px;
    background: white;
    .faq_top {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        text-align: justify;
        .faq_heading 
        {
            font-size: 20px;
            font-weight: 400;
        }
        span 
        {
            font-size: 22px;
            transform: rotateX(180deg);
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .faq_bottom {
        padding: 0px 10px 10px 10px;
        height: fit-content;
        color: var(--text);
        text-align: justify;
    }

    .faq_bottom1 {
        padding: 0px 0px 0px 0px;
        height: 0px;
        overflow: hidden;
    }
}
}
@media only screen and (max-width: 1000px) 
{
    .faqs_container { 

        width: 100% !important;
    }
}