.aboutus-card {
    height: fit-content;
    display: flex;
    padding: 150px 0px 70px 0px;


    .aboutus-card-left {
        // padding-top: 50px;
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .left-box {
            aspect-ratio: 1 / 1;
            width: 512px;
            height: 512px;


            background: url('../photos/home//home.jpg');
            background-position: center center !important;
            background-size: cover !important;
            background-repeat: no-repeat !important;
            border-radius: var(--br);
            display: flex;
            align-items: flex-end;
            position: relative;

            .inner-box {
                width: 300px;
                height: 200px;
                border-radius: 10px;
                outline: 10px solid white;
                overflow: hidden;
                position: absolute;
                right: 43%;
                top: 63%;
                background: url('../photos/home/Artboard\ 8.webp');
                background-position: center center !important;
                background-size: cover !important;
                background-repeat: no-repeat !important;

            }
        }
    }

    .aboutus-card-right {
        width: 50%;
        height: 100%;
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: var(--gap);

        .about-header {
            color: var(--black);
            font-weight: var(--bold) !important;
            font-size: var(--med-font);
        }

        .about-content {
            color: var(--text);
            font-size: var(--small-font);
            font-weight: var(--bold);
            text-align: justify;
        }

        .review-box-parent {
            display: flex;
            flex-wrap: wrap;

            .review-box {
                font-size: var(--small-font);
                display: flex;
                align-items: center;
                width: 50%;
                height: 30px;
                flex-grow: 0;
                flex-shrink: 0;
                box-sizing: border-box;

                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 25px !important;
                    font-weight: var(--bolder) !important;
                }
            }
        }
    }
}

.about-divider-counter {

    display: flex;
    gap: var(--gap);
    width: fit-content;
    flex-wrap: wrap;

    .counter-box {

        width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: var(--golden);
        border-radius: 10px;
        padding: 15px 20px;
        position: relative;
        width: 150px;

        .counter-number {
            font-size: var(--large-font);
            font-weight: 700;
            color: white;
        }

        .counter-text {
            font-size: var(--extra-small-font);

        }

        .counter-icon {
            background-color: var(--white);
            padding: 5px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            color: var(--black);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 22px;
            position: absolute;
            top: -20%;
            right: -8%;
            box-shadow: inset 0 0 10px var(--golden);

            &.linkedin {
                color: #0073B2;
            }

            &.facebookicon {
                color: #0866FF;
            }
        }
    }
}

@media only screen and (max-width: 1150px) {
    .aboutus-card {
        flex-direction: column;
        align-items: center;

        .aboutus-card-right {
            width: 100%;
        }

        .aboutus-card-left {
            width: 100%;
            padding: 0px;
        }
    }
}

@media only screen and (max-width: 1000px) {
    .aboutus-card {
        flex-direction: column;
        align-items: center;

        .aboutus-card-right {
            width: 100%;
            // padding: 0px 20px;
        }

        .aboutus-card-left {
            width: 100%;
            padding: 20px 0px 0px 0px;

            .left-box {
                width: 400px;
                height: 400px;

                .inner-box {
                    width: 250px;
                    height: 150px;
                }
            }
        }
    }
}


@media only screen and (max-width: 600px) {
    .aboutus-card {
        padding: 110px 0px 0px 0px;

        .aboutus-card-left {
            .left-box {
                width: 300px;
                height: 300px;

                .inner-box {
                    width: 180px;
                    height: 110px;
                }
            }
        }

        .aboutus-card-right {
            margin: 20px 0px;

            .about-divider-counter {
                flex-direction: column;
                align-items: center;
                width: 100%;

                .counter-box {
                    width: 50%;
                }
            }
        }
    }
}