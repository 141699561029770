.search-parent {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .search-container {
        position: absolute;
        top: -50%;
        width: 100%;
        background: white;
        border-radius: var(--br);
        overflow: hidden;
        height: 40px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    }
}

.allblogsparent {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 50px 0px;

    .blogpage-title {
        width: 100%;
        text-align: center;
        padding: 10px 0px;
        font-size: var(--large-font);
    }

    .allblogscontainer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: var(--gap);
        align-items: center;
        justify-content: center;
    }

    .pagination-buttons {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0px;
    }
}

.filter-parent {
    .filter-container {
        padding-top: 20px;

        .filter-box {

            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            width: fit-content;
            height: fit-content;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            padding: 10px;
            border-radius: 5px;

            .input {
                width: 500px;
                height: 56px;
                border: 1px solid #dadada;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: space-around;
                overflow: hidden;

                input {
                    height: 100%;
                    width: 100%;
                    border: none;
                    font-size: var(--med-font);
                    padding: 0px 10px;
                    color: black;

                    &:focus {
                        outline: none;
                    }

                }

                .search-icon {
                    font-size: 26px;
                    padding: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                }
            }

            .reset-btn {
                height: 57px !important;
            }

            .MuiFormControl-root  
            {
                width: 200px !important;
            }
        }
    }
}

@media only screen and (max-width:950px) {

    .filter-parent {
        .filter-container {
            padding-top: 20px;

            .filter-box {
                flex-direction: column;
            .input 
            {
             
            }
            .MuiFormControl-root
            {
                width: 100% !important;
            }
            .reset-btn 
            {
                width: 100% !important;
            }
            }
        }
    }
}
@media only screen and (max-width:600px) {

    .filter-parent {
        .filter-container {
            padding-top: 20px;

            .filter-box {
                flex-direction: column;
            .input 
            {
                width: 100% !important; 
            }
            .MuiFormControl-root
            {
                width: 100% !important;
            }
            .reset-btn 
            {
                width: 100% !important;
            }
            }
        }
    }
}